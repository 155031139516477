import copy from 'copy-to-clipboard';
import React, { useEffect, useState } from 'react';
import { Tab } from 'material-ui/Tabs';
import Link from '@material-ui/core/Link';
import { Trans, useTranslation } from 'react-i18next';
import Tabs from '../../components/tabs';

import './styles.css';
import RedeemBonus from './modules/redeemBonus';
import ReferralsList from './modules/referralsList';
import RedeemHistory from './modules/redeemHistory';
import DialogInfo from './components/DialogInfo';

const styles = {
  tab: {
    fontSize: '11px',
    height: 40,
    width: 120,
  },
};

const Referral = ({ link, fetchReferralLink, onCopy }) => {
  const [infoDialog, setInfoDialog] = useState(false);
  const { t } = useTranslation();
  const toggleHandler = () => {
    setInfoDialog(!infoDialog);
  };

  useEffect(() => {
    fetchReferralLink();
  }, []);

  return (
    <div>
      <div className="referral">
        <div className="referral-section referral-container">
          <h3 className="header">
            <Trans>Refer to a Friend</Trans>
          </h3>
          <div className="refer-friend">
            <div className="help-text">
              <Trans>
                Use your unique referral link to invite your friends to sign up
                for Beezer.
              </Trans>
              <b>
                <Trans>
                  For every one of your referrals that signs up to a paid Beezer
                  subscription, you'll earn cash back
                </Trans>
              </b>
              <Trans>
                in the form of redeemable bonus points at the value of 50% of
                your referral's first subscription payment.
              </Trans>
              <span className="more">
                <Link href="#" onClick={e => toggleHandler()}>
                  <Trans>Find out more</Trans>
                </Link>
              </span>
            </div>
            <div className="details flex-row subscription">
              <div>
                <span
                  className="share-button"
                  onClick={() => {
                    copy(link);
                    onCopy(true);
                  }}
                >
                  <Trans>Copy to share</Trans>
                </span>
              </div>
            </div>
          </div>
        </div>
        <RedeemBonus />
      </div>
      <div className="referral">
        <div className="account-tabs referral-tabs">
          <Tabs>
            <Tab style={styles.tab} label={t('Referrals')}>
              <ReferralsList />
            </Tab>
            <Tab style={styles.tab} label={t('Redeem History')}>
              <RedeemHistory />
            </Tab>
          </Tabs>
        </div>
      </div>
      <DialogInfo
        open={infoDialog}
        onClose={toggleHandler}
        title={t('Refer a Friend')}
      >
        <div>
          <div>
            <Trans>
              Use your unique referral link to invite your friends to sign up
              for Beezer.
            </Trans>
            <b>
              <Trans>
                For every one of your referrals that signs up to a paid Beezer
                subscription, you'll earn cash back
              </Trans>
            </b>
            <Trans>
              in the form of redeemable bonus points at the value of 50% of your
              referral's first subscription payment.
            </Trans>
          </div>
          <div>
            <Trans>
              For example, if the friend you referred signs up to our Everything
              Monthly plan, you'll see £21 in your account as soon as they pay
              their £42. If they sign up to our Everything Yearly plan,
              cha-ching! That's £228 in your account as soon as they've made
              their first subscription payment.
            </Trans>
          </div>
          <div>
            <Trans>
              Redeem your points and get the money transferred to your saved
              payment method at any time.
            </Trans>
          </div>
        </div>
      </DialogInfo>
    </div>
  );
};
export default Referral;
