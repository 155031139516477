/* global fetch Headers*/
import ReactGA from 'react-ga';
import axios from 'axios';
import firebase from '../../../../firebase';
import { newValidator } from '../../../../utils';
import { receiveLogin, sendEmailVerification } from '../auth/login/actions';
import { updateContact } from '../auth/login/actions';
import { addOffersAnalytics } from '../../../analytics/actions';
import { plans } from '../../../../plans';
import { updateAccount, loadAccount } from '../../actions';
import { referrerList } from './referrerList.js';
import { logout } from '../auth/actions';

/************************************
 ************* PROFILE **************
 ************************************/

const loadingProfile = () => ({
  type: 'LOADING_PROFILE',
});

const hydrateProfile = data => ({
  type: 'HYDRATE_PROFILE',
  data,
});

const appsumoUserDetail = (data, id) => ({
  type: 'APPSUMO_USER_DETAIL',
  data,
  id,
});

const appsumoUserError = err => ({
  type: 'APPSUMO_USER_ERROR',
  err,
});

const referrerDetails = payload => ({
  type: 'REFERRER_DETAILS',
  payload,
});
/**
 * Loads user 'profile' from /users/:uid
 * @returns {Promise} - Loads user profile from uid
 */
export const loadProfile = () => dispatch => {
  dispatch(loadingProfile());

  return new Promise((resolve, reject) => {
    const { uid } = firebase.auth().currentUser;
    const ref = firebase.database().ref(`users/${uid}`);

    ref.once('value', snapshot => {
      const data = snapshot.val();
      if (snapshot.exists()) {
        dispatch(hydrateProfile(data));
        return resolve(data);
      }

      return reject(new Error('Profile does not exist'));
    });
  });
};

const getIpData = uid => dispatch => {
  return firebase
    .database()
    .ref(`users/${uid}/ip_data/`)
    .once('value', snapshot => {
      if (!snapshot.exists()) {
        axios
          .get('https://ipapi.co/json/')
          .then(res => {
            firebase
              .database()
              .ref(`users/${uid}/ip_data/`)
              .update({
                ip_address: res.data.ip,
                city: res.data.city,
                country: res.data.country_name,
                currency: res.data.currency,
                showDynamicPricing: true,
              })
              .then(() => {
                const ipData = res.data.ip;
                return firebase
                  .database()
                  .ref('blacklisted_ip')
                  .once('value')
                  .then(blacklistIP => {
                    const list = blacklistIP.val();
                    if (list.includes(ipData)) {
                      dispatch(logout());
                    } else {
                      dispatch({ type: 'USER_NOT_BLOCKED' });
                    }
                  });
              });
          })
          .catch(err => console.error(err));
      }
    });
};
export const resetRegistrationForm = () => ({
  type: 'RESET_REGISTRATION_FORM',
});

export const fieldChange = (prop, value) => ({
  type: 'REGISTRATION_FIELD_CHANGE',
  prop,
  value,
});

export const saveOfferFromQueryString = offer => ({
  type: 'SAVING_OFFER_FROM_QUERYSTRING',
  offer,
});

export const resetQueryStringOffer = () => ({
  type: 'RESET_QUERY_STRING_OFFER',
});

// Creates account (organisation and user profile)
export const createAccount = (
  email,
  name,
  referrer,
  workfield,
  appsumoUserId,
  dealFuelReferrer,
) =>
  new Promise((resolve, reject) => {
    const user = firebase.auth().currentUser;

    return user.getIdToken().then(token => {
      const headers = new Headers();
      headers.append('Content-Type', 'application/json');
      headers.append('x-access-token', token);

      return fetch(`${process.env.REACT_APP_CREATE_API}/account`, {
        method: 'post',
        mode: 'cors',
        body: JSON.stringify({
          email,
          name,
          referrer,
          workfield,
          appsumoUserId,
          dealFuelReferrer,
        }),
        headers,
      })
        .then(res => {
          if (res.status === 200) {
            return resolve(res);
          }

          throw new Error('Opps, something went wrong. Please try again.');
        })
        .catch(reject);
    });
  });

export const validationError = prop => ({
  type: 'REGISTRATION_VALIDATION_ERROR',
  prop,
});

export const registrationError = err => ({
  type: 'REGISTRATION_ERROR',
  err,
});

export const emailValidator = newValidator({
  type: 'email',
  required: true,
});

export const passwordValidator = newValidator({
  minLength: 6,
  required: true,
});

// Platform inside Wix dashboard
const isInsideDashboard = () => {
  if (window.self === window.top) {
    return false;
  }
  return true;
};

const loginError = message => ({
  type: 'LOGIN_FAILURE',
  message,
});

const getAppsumoUserInfo = id =>
  new Promise((resolve, reject) => {
    firebase
      .database()
      .ref(`appsumo/${id}`)
      .once('value')
      .then(snapshot => {
        if (!snapshot.exists()) {
          return resolve(null);
        }
        return resolve(snapshot.val());
      })
      .catch(err => console.error(err));
  });

const addAppsumoPlanInfoToAccount = async (accountId, id) => {
  const userInfo = await getAppsumoUserInfo(id);
  await firebase
    .database()
    .ref(`accounts/${accountId}`)
    .update({
      appsumo_id: id,
      trial_expired: true,
      sms_credit: plans[userInfo.plan_id].sms_credit,
      whatsapp_sms_credit: plans[userInfo.plan_id].whatsapp_sms_credit,
      legacy_flag: plans[userInfo.plan_id].legacy_flag || null,
      appsumo_2022: true,
    });
};

const addAppsumoToBilling = async (accountId, id) => {
  try {
    const appsumoUser = await getAppsumoUserInfo(id);
    const billingData = {
      plan: {
        id: appsumoUser && appsumoUser.plan_id,
        name: 'Appsumo',
        interval: 'lifetime',
      },
      period: 'lifetime',
      created: new Date().valueOf(),
      amount:
        parseInt(plans[appsumoUser.plan_id].dealPrice.split('$')[1]) * 100,
      currency: 'USD',
      type: 'subscription',
    };
    await firebase
      .database()
      .ref('billing')
      .child(accountId)
      .push(billingData);
  } catch (err) {
    console.error(err);
  }
};

export const loginUser = (email, password, cb) => dispatch =>
  firebase
    .auth()
    .signInWithEmailAndPassword(email, password)
    .then(() => cb(true))
    .catch(error => dispatch(loginError(error.message)));

export const addWelcomeNotice = (account, offer) => {
  let content = '';
  let mobileContent = '';
  let type = 'Welcome';
  let allowedRedemptions = '';
  let totalRedemptions = '';
  let obj = {};
  if (offer && offer.coupon) {
    content = offer.welcome_notice;
    mobileContent = offer.welcome_notice_mobile || '';
    type = 'Offer';
    allowedRedemptions = 1000;
    totalRedemptions = 100;
    obj = {
      content,
      mobileContent,
      type,
      autoPopup: true,
      allowedRedemptions,
      totalRedemptions,
    };
  } else {
    obj = { content, mobileContent, type, autoPopup: true };
  }

  return firebase
    .database()
    .ref(`accounts/${account}`)
    .child('notices')
    .update({
      '-1': obj,
    })
    .catch(err => console.error(err));
};

export const setOfferEligibility = (account, offer) => {
  if (offer && offer.coupon) {
    //check if this offer not already exists for lifetime
    return firebase
      .database()
      .ref(`accounts/${account}/offerEligibility/${[offer.coupon]}`)
      .once('value')
      .then(snapshot => {
        const dbOfferVal = snapshot.val();

        if (
          offer.coupon === 'lifetime' &&
          (dbOfferVal === true || dbOfferVal === false)
        ) {
          return Promise.reject();
        }
        return firebase
          .database()
          .ref(`accounts/${account}`)
          .child('offerEligibility')
          .update({ [offer.coupon]: true })
          .then(() => account)
          .catch(err => console.error(err));
      })
      .catch(err => console.error(err));
  }
  if (offer && !offer.coupon) {
    return firebase
      .database()
      .ref(`accounts/${account}`)
      .child('offerEligibility')
      .update({ [offer]: true })
      .then(() => account)
      .catch(err => console.error(err));
  }
  return account;
};

export const setReferralOfferEligibility = (account, offer) => {
  if (offer) {
    return firebase
      .database()
      .ref(`accounts/${account}`)
      .child('referralOfferEligibility')
      .update({ [offer]: true })
      .then(() => account)
      .catch(err => console.error(err));
  }
  return account;
};

/**
 * REGISTER
 * Calls firebase creatUser.
 * Next step is handled by startListeningToAuthChange
 */
export const registerUser = (
  callback,
  referrer,
  offer,
  dealFuelReferrer = null,
) => async (dispatch, getState) => {
  const {
    name,
    email,
    password,
    agreements,
    workfield,
    appsumoUserId,
    appsumoUser,
  } = getState().account.register;

  let error = false;

  const isAppAdmin =
    (getState().account.auth.profile.admin_appname &&
      getState().account.auth.profile.admin_appname.length > 0) ||
    false;

  if (name.value === '') {
    return dispatch(validationError('name'));
  }

  if (workfield.value === '' || workfield.value === null) {
    return dispatch(validationError('workfield'));
  }

  if (!agreements.value) {
    return dispatch(validationError('agreements'));
  }

  if (!emailValidator(email.value)) {
    return dispatch(validationError('email'));
  }

  if (!passwordValidator(password.value)) {
    return dispatch(validationError('password'));
  }

  if (!passwordValidator(password.value)) {
    return dispatch(validationError('password'));
  }

  //if user is not using appsumo activation link check if whether or not user has already bought a plan on appsumo
  //Warn appsumo user to only use appsumo activation link to activate their account
  if (!appsumoUser && (await isAppsumoPaidUser(email.value))) {
    return dispatch(
      registrationError(
        'Use your appsumo activation link to register. For any further assistance contact our support team',
      ),
    );
  }

  if (isInsideDashboard()) {
    const myAccountUrl = 'https://www.wix.com/my-account/sites/';
    window.top.location.replace(myAccountUrl);
  }

  dispatch({ type: 'SUBMIT_REGISTRATION', name, email, password });

  if (dealFuelReferrer) {
    const key = dealFuelReferrer.key;
    const ref = dealFuelReferrer.ref;
    if (ref === 'dealfuel' && key) {
      await firebase
        .database()
        .ref(`dealfuel_licenses/${key}`)
        .once('value')
        .then(snapshot => {
          const exists = snapshot.exists();
          const value = snapshot.val();
          if (!exists) {
            error = true;
            dispatch(
              registrationError(
                'Unable to activate account. License key not found.',
              ),
            );
          } else if (exists && value.status === 'used') {
            error = true;
            dispatch(
              registrationError(
                'Unable to activate account. License key has already been used.',
              ),
            );
          }
        });
    }
  }

  if (error) {
    return;
  }

  return firebase
    .auth()
    .createUserWithEmailAndPassword(email.value, password.value)
    .then(() =>
      createAccount(
        email.value,
        name.value,
        referrer,
        workfield.value,
        appsumoUserId,
        dealFuelReferrer && dealFuelReferrer.ref === 'dealfuel'
          ? dealFuelReferrer
          : null,
      ),
    )
    .then(() => createContact(email.value, name.value))
    .then(() => {
      callback(email.value, password.value);
      dispatch({ type: 'REGISTRATION_COMPLETE' });
    })
    .then(() => dispatch(receiveLogin()))
    .then(async profile => {
      const isAppsumoAccount =
        !!getState().account.register.appsumoUserId ||
        !!getState().account.organisation.appsumoUserId ||
        appsumoUserId;
      const lists = isAppsumoAccount
        ? 'appsumo-users-2022'
        : referrer
        ? referrer
        : 'new-signups';

      const user = firebase.auth().currentUser;
      dispatch(getIpData(user.uid));
      dispatch({ type: 'LOGIN_SUCCESS', user });
      dispatch(loadProfile());
      dispatch(
        updateContact({
          account: profile.account,
          lists,
        }),
      );
      return profile;
    })
    .then(profile => {
      if (offer) {
        if (window.fbq) {
          // Facebook analytics
          window.fbq('track', 'CompleteRegistration');
        }
        addOffersAnalytics('registered', offer.coupon, profile.account);
        ReactGA.event({
          category: 'User',
          action: `User registered with ${offer.coupon}`,
        });
        setOfferEligibility(profile.account, offer);
      }

      if (
        referrerList[referrer] &&
        referrerList[referrer].offer &&
        referrerList[referrer].offer.coupons
      ) {
        Object.keys(referrerList[referrer].offer.coupons).forEach(offer => {
          setReferralOfferEligibility(profile.account, offer);
        });
      }
      return profile.account;
    })
    .then(async account => {
      if (appsumoUserId) {
        await addAppsumoPlanInfoToAccount(account, appsumoUserId);
        await addAppsumoToBilling(account, appsumoUserId);
        await dispatch(loadAccount(account));
        const userInfo = await getAppsumoUserInfo(appsumoUserId);
        const lists = `appsumo-users-2022-${userInfo.plan_id}`;
        dispatch(
          updateContact({
            account,
            lists,
          }),
        );
      } else if (!appsumoUserId && !isAppAdmin) {
        addWelcomeNotice(account, offer);
      }
    })
    .then(() => dispatch(resetQueryStringOffer()))
    .then(() => dispatch(sendEmailVerification(email.value)))
    .then(() => dispatch(loadProfile()))
    .catch(error => dispatch(registrationError(error.message)));
};

export const createContact = async (email, name) => {
  const myHeaders = new Headers();
  await firebase
    .auth()
    .currentUser.getIdToken()
    .then(token => {
      myHeaders.append('Content-Type', 'application/json');
      myHeaders.append('x-access-token', token);
      return fetch(`${process.env.REACT_APP_FRESHMARKETER_API}/create`, {
        headers: myHeaders,
        method: 'post',
        mode: 'cors',
        body: JSON.stringify({
          email,
          name,
        }),
      });
    });
};

export const fetchAppsumoUserDetail = id => dispatch =>
  id
    ? firebase
        .database()
        .ref(`appsumo/${id}`)
        .once('value')
        .then(snapshot => {
          if (snapshot.exists()) {
            dispatch(appsumoUserDetail(snapshot.val(), id));
            dispatch(updateAccount('appsumo_id', id));
          }
        })
        .catch(error => dispatch(appsumoUserError(error.message)))
    : Promise.resolve();

const isAppsumoPaidUser = async email => {
  const appsumoUser = await firebase
    .database()
    .ref('appsumo')
    .orderByChild('activation_email')
    .equalTo(email)
    .once('value');

  return appsumoUser.exists();
};

// checks if there is a valid referrer
export const getReferrerDetials = referrer => dispatch => {
  if (!referrer) return dispatch(referrerDetails(null));
  if (referrerList[referrer])
    return dispatch(referrerDetails(referrerList[referrer]));
  return dispatch(referrerDetails(null));
};
