import React from 'react';
import PropTypes from 'prop-types';

import TextAreaInput from './TextAreaInput';
import TextAreaLabel from './TextAreaLabel';
import TextAreaContainer from './TextAreaContainer';
import './styles.css';

const TextArea = ({
  id,
  label,
  value,
  onChange,
  onBlur,
  disabled,
  minHeight,
  required,
  isValid,
  errorText,
  showRequiredAsterick,
  applyPgmolLabelStyle,
  border,
}) => {
  return (
    <TextAreaContainer className="text-area-container">
      {!applyPgmolLabelStyle && (
        <TextAreaLabel
          htmlFor={id}
          className={`${showRequiredAsterick &&
            'required-asterik'} ${applyPgmolLabelStyle && 'pgmol-label-style'}`}
        >
          {' '}
          {label}{' '}
        </TextAreaLabel>
      )}

      <div className="textarea-container">
        <TextAreaInput
          name={id}
          value={value}
          onChange={e => onChange(e.target.value)}
          onBlur={onBlur}
          className="text-area"
          disabled={disabled}
          style={{
            minHeight,
            borderBottom: `${
              isValid === false && required && border ? '1px solid red' : ''
            }`,
          }}
          required={required}
          data-gramm="false"
        />
        {applyPgmolLabelStyle && (
          <p
            className={`${
              showRequiredAsterick ? 'required-label' : 'no-label'
            }`}
          >
            {label}
          </p>
        )}
      </div>

      {isValid === false && required && (
        <span style={{ color: 'red', fontSize: '0.8rem' }}>{errorText}</span>
      )}
    </TextAreaContainer>
  );
};

TextArea.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  minHeight: PropTypes.string,
  required: PropTypes.bool,
  isValid: PropTypes.bool,
  errorText: PropTypes.string,
  showRequiredAsterick: PropTypes.bool,
  applyPgmolLabelStyle: PropTypes.bool,
  border: PropTypes.bool,
};

TextArea.defaultProps = {
  onBlur: () => {},
  disabled: false,
  value: '',
  minHeight: '160px',
  required: false,
  isValid: true,
  errorText: '',
  showRequiredAsterick: false,
  applyPgmolLabelStyle: false,
  border: true,
};

export default TextArea;
