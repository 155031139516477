import React, { useState, useEffect } from 'react';
import { useMutation, useQuery } from 'react-query';
import {
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  FormHelperText,
} from '@material-ui/core';

import Coupons from '../Coupons/Coupons';
import Toggle from '../../../../app/components/toggle';
import { setMetaData, getMetaData, getStripeDetails } from '../../api';
import { useDispatch } from 'react-redux';
import { STRIPE_CURRENCIES } from '../../../../config/constants';
import { openSnackbar } from '../../../../app/modules/snackbar/actions';
import styles from './styles.module.scss';
import themeStyles from '../../../../_export.scss';
import { Trans, useTranslation } from 'react-i18next';

const MetaData = props => {
  const { t } = useTranslation();
  const { appname, isAppAdmin } = props;
  const { mutateAsync, isLoading } = useMutation(setMetaData);
  const { data, error, isLoading: isLoadingData, isError } = useQuery(
    ['ecommerceMetaData', { appname }],
    getMetaData,
  );
  const { data: stripeData, isLoading: stripeisLoading } = useQuery(
    ['ecommerceStripeSecret', { appname }],
    getStripeDetails,
  );

  const [isToggle, setIsToggled] = useState(false);
  const [taxRate, setTaxRate] = useState('');
  const [currency, setCurrency] = useState('');
  // const [currencies, setCurrencies] = useState({});
  const [stock, setStock] = useState(false);
  const [needMemberLogin, setNeedMemberLogin] = useState(false);
  const [stripePaymentOption, setStripePaymentOption] = useState(false);
  const [cashOnDeliveryOption, setCashOnDeliveryOption] = useState(false);

  const [pickUpOption, setPickUpOption] = useState(false);
  const [pickUpLocation, setPickUpLocation] = useState('');

  const [stripePublishableKey, setStripePublishableKey] = useState('');
  const [stripeSecretKey, setStripeSecretKey] = useState('');
  const [deliveryCharge, setDeliveryCharge] = useState(0);
  const [email, setEmail] = useState('');
  const [currencyError, setCurrencyError] = useState(false);
  const [paymentOptionsError, setPaymentOptionsError] = useState('');
  const [coupons, setCoupons] = useState([
    { couponCode: '', discountRate: '' },
  ]);
  const dispatch = useDispatch();
  useEffect(() => {
    if (data) {
      setTaxRate(data.taxRate);
      setIsToggled(data.taxEnabled);
      setCurrency(data.currency);
      setStock(data.continueAfterStock);
      setNeedMemberLogin(data.needMemberLogin);
      setStripePublishableKey(data.stripePublishableKey);
      setEmail(data.email);
      data.pickUpLocation !== undefined &&
        setPickUpLocation(data.pickUpLocation);
      data.pickUpOption !== undefined && setPickUpOption(data.pickUpOption);
      setDeliveryCharge(data.deliveryCharge || 0);
      setCoupons(data.coupons || [{ couponCode: '', discountRate: '' }]);
      if (data.paymentOptions) {
        setStripePaymentOption(data.paymentOptions.stripePaymentOption);
        setCashOnDeliveryOption(data.paymentOptions.cashOnDeliveryOption);
      } else if (data.stripePublishableKey) {
        setStripePaymentOption(true);
      }
    }
  }, [data]);

  useEffect(() => {
    if (stripeData) {
      setStripeSecretKey(stripeData.stripeSecretKey);
    }
  }, [stripeData]);

  useEffect(() => {
    if (!stripePaymentOption || !cashOnDeliveryOption || !pickUpOption) {
      setPaymentOptionsError('');
    }
  }, [stripePaymentOption, cashOnDeliveryOption]);

  const handleSubmit = async e => {
    try {
      e.preventDefault();

      if (!currency) {
        setCurrencyError(true);
        return;
      }

      if (!stripePaymentOption && !cashOnDeliveryOption) {
        setPaymentOptionsError(t('Select at least one payment option'));
        return;
      }

      if (!pickUpOption || !cashOnDeliveryOption) {
        if (pickUpOption && !stripePaymentOption) {
          setPaymentOptionsError(
            t('Enable stripe payments option for order pickups'),
          );
          return;
        }
      }

      if (currencyError) setCurrencyError(false);

      const data = {
        appname,
        taxEnabled: isToggle,
        taxRate,
        deliveryCharge,
        currency,
        continueAfterStock: stock,
        stripePublishableKey,
        stripeSecretKey,
        pickUpLocation,
        email,
        coupons,
        needMemberLogin,
        pickUpOption,
        paymentOptions: {
          stripePaymentOption,
          cashOnDeliveryOption,
        },
      };
      await mutateAsync({ ...data });
      dispatch(openSnackbar(t('Settings updated successfully')));
    } catch (err) {
      console.error(err);
    }
  };

  if (isLoadingData || stripeisLoading || isLoading)
    return (
      <div>
        <Trans>Loading...</Trans>
      </div>
    );

  if (isError) {
    return (
      <span>
        <Trans>Error:</Trans> {error.message}
      </span>
    );
  }
  const docLink =
    'https://beezer.zendesk.com/hc/en-us/articles/360049266472-Subscription-Manager';
  const docPDFLink =
    'https://firebasestorage.googleapis.com/v0/b/beezer-c947f.appspot.com/o/Instructions%2FStripe%20Setup%20-%20Food%20component.pdf?alt=media&token=4fbe3016-2301-45f0-9fe3-4af2170bc52a';
  const getInstructionLink = () =>
    isAppAdmin ? (
      <a
        href={docPDFLink}
        rel="noopener noreferrer"
        style={{ color: themeStyles.primaryColor, fontWeight: 'bold' }}
        download
        target="_blank"
      >
        <Trans>
          Click here to see how to set up your Stripe business account
        </Trans>
      </a>
    ) : (
      <a
        href={docLink}
        target="_blank"
        rel="noopener noreferrer"
        style={{ color: themeStyles.primaryColor, fontWeight: 'bold' }}
      >
        <Trans>
          Click here to see how to set up your Stripe business account
        </Trans>
      </a>
    );

  return (
    <div className="auth-panel">
      <h2 className="auth-panel-title">
        <Trans>Settings</Trans>
      </h2>
      <div className={styles.container}>
        <form onSubmit={handleSubmit} autoComplete="off">
          <h3 className={styles.settings_header}>
            <Trans>Order Settings</Trans>
          </h3>
          <div className={styles.tax}>
            <h4>
              <Trans>Enable Tax</Trans>
            </h4>
            <Toggle
              toggled={isToggle}
              onToggle={(e, toggle) => setIsToggled(toggle)}
            />
            <label htmlFor="taxRate">
              <Trans>Tax rate in %</Trans>
            </label>
            <input
              id="taxRate"
              type="number"
              min="0"
              max="100"
              step="0.01"
              placeholder="8"
              disabled={!isToggle}
              value={taxRate}
              onChange={e =>
                setTaxRate(parseFloat(parseFloat(e.target.value).toFixed(2)))
              }
              required={isToggle}
            />
          </div>
          <div className={styles.stripe}>
            <h4>
              <Trans>Delivery Charge</Trans>:
            </h4>
            <input
              id="deliveryCharge"
              value={deliveryCharge}
              onChange={e => setDeliveryCharge(e.target.value)}
            />
          </div>
          <div className={styles.currency}>
            <h4>
              <Trans>Select a currency</Trans>
            </h4>
            <Select
              value={currency}
              onChange={e => setCurrency(e.target.value)}
              displayEmpty
            >
              <MenuItem value="" disabled>
                <Trans>Select a currency</Trans>
              </MenuItem>
              {STRIPE_CURRENCIES.map(c => (
                <MenuItem key={c.value} value={c.value}>
                  {c.label}
                </MenuItem>
              ))}
            </Select>
            {currencyError && (
              <FormHelperText style={{ color: 'red' }}>
                <Trans>This is required!</Trans>
              </FormHelperText>
            )}
          </div>
          <div className={styles.stock}>
            <FormControlLabel
              value="start"
              control={
                <Checkbox
                  style={{ color: themeStyles.primaryColor }}
                  checked={stock}
                  onChange={() => setStock(prev => !prev)}
                />
              }
              label={t('Continue selling when out of stock')}
              labelPlacement="start"
            />
          </div>
          <div className={styles.stock}>
            <FormControlLabel
              value="start"
              control={
                <Checkbox
                  style={{ color: themeStyles.primaryColor }}
                  checked={needMemberLogin}
                  onChange={() => setNeedMemberLogin(prev => !prev)}
                />
              }
              label={t('Only signed-in users can make purchases')}
              labelPlacement="start"
            />
          </div>

          <div className={styles.stripe}>
            <h4>
              <Trans>Email to send order updates to</Trans>
            </h4>
            <input
              type="email"
              placeholder="janedoe@gmail.com"
              value={email}
              onChange={e => setEmail(e.target.value)}
              required
            />
          </div>

          <Coupons coupons={coupons} setCoupons={setCoupons} />
          <div>
            <FormControlLabel
              classes={{ root: styles.settings_label }}
              control={
                <Checkbox
                  style={{ color: themeStyles.primaryColor }}
                  checked={pickUpOption}
                  onChange={() => setPickUpOption(prev => !prev)}
                />
              }
              label={
                <h4>
                  <Trans>Pickup Orders</Trans>
                </h4>
              }
              labelPlacement="start"
            />
          </div>
          <div className={!pickUpOption && styles.disabled_container}>
            <h4 style={{ display: 'inline-block' }}>
              <Trans>Pickup Details</Trans>:
            </h4>
            <span>
              <label htmlFor="pickUpLocation">
                <Trans>Location</Trans>:
              </label>
              <input
                id="pickUpLocation"
                value={pickUpLocation}
                style={{ width: '50vw', maxWidth: '450px' }}
                onChange={e => setPickUpLocation(e.target.value)}
                required
                disabled={!pickUpOption}
              />
            </span>
          </div>
          <div className={styles.stripe}>
            <h3
              className={`${styles.settings_header} ${styles.settings_header_custom}`}
            >
              <Trans>Payment options</Trans>
            </h3>
            <div className={styles.container}>
              <div>
                <FormControlLabel
                  classes={{ root: styles.settings_label }}
                  control={
                    <Checkbox
                      style={{ color: themeStyles.primaryColor }}
                      checked={stripePaymentOption}
                      onChange={() => setStripePaymentOption(prev => !prev)}
                    />
                  }
                  label={
                    <h4>
                      <Trans>Use Stripe Payment</Trans>
                    </h4>
                  }
                  labelPlacement="start"
                  disabled={pickUpOption && cashOnDeliveryOption}
                />
              </div>
              <div
                className={
                  (!stripePaymentOption ||
                    (pickUpOption && cashOnDeliveryOption)) &&
                  styles.disabled_container
                }
              >
                <h4 style={{ display: 'inline-block' }}>
                  <Trans>Stripe Payment Details</Trans>:
                </h4>
                <span>
                  <label htmlFor="stripePublishableKey">
                    <Trans>Publishable key</Trans>:
                  </label>
                  <input
                    id="stripePublishableKey"
                    value={stripePublishableKey}
                    onChange={e => setStripePublishableKey(e.target.value)}
                    required
                    disabled={
                      !stripePaymentOption ||
                      (pickUpOption && cashOnDeliveryOption)
                    }
                  />
                </span>
                <span>
                  <label htmlFor="stripeSecretKey">
                    <Trans>Secret key</Trans>:
                  </label>
                  <input
                    id="stripeSecretKey"
                    value={stripeSecretKey}
                    onChange={e => setStripeSecretKey(e.target.value)}
                    required
                    disabled={
                      !stripePaymentOption ||
                      (pickUpOption && cashOnDeliveryOption)
                    }
                  />
                </span>
              </div>
              {getInstructionLink()}
              <div>
                <FormControlLabel
                  classes={{ root: styles.settings_label }}
                  control={
                    <Checkbox
                      style={{ color: themeStyles.primaryColor }}
                      checked={cashOnDeliveryOption}
                      onChange={() => setCashOnDeliveryOption(prev => !prev)}
                    />
                  }
                  label={
                    <h4>
                      <Trans>Use Cash On Delivery</Trans>
                    </h4>
                  }
                  labelPlacement="start"
                />
              </div>
            </div>
            {paymentOptionsError && (
              <FormHelperText style={{ color: 'red' }}>
                {paymentOptionsError}
              </FormHelperText>
            )}
          </div>
          <button className={styles.button} type="submit">
            {isLoading ? t('SAVING...') : t('SAVE')}
          </button>
        </form>
      </div>
    </div>
  );
};

export default MetaData;
