import moment from 'moment';
import i18n from 'i18next';

/* global Stripe fetch Headers */
import firebase from '../../../../firebase';
import { openSnackbar } from '../../../../app/modules/snackbar/actions';
import { findFirst } from '../../../../utils';
import { updateContact } from '../auth/login/actions';

export const submitPayment = () => ({
  type: 'SUBMIT_PAYMENT_DETAILS',
});

export const handleFormChange = (prop, value, error) => ({
  type: 'PAYMENT_FORM_CHANGE',
  prop,
  value,
  error,
});

export const clearPaymentForm = () => ({
  type: 'CLEAR_PAYMENT_FORM',
});

export const subscriptionPaymentRequiresAuthentication = (
  subscription,
  diySubToCancel,
) => ({
  type: 'SUBSCRIPTION_PAYMENT_REQUIRES_AUTHENTICATION',
  subscription,
  diySubToCancel,
});
export const reportPaymentSuccess = () => (dispatch, getState) => {
  //Report Payment succsess to Freshmarketer
  const profile = getState().account.profile;
  const org = getState().account.organisation;
  dispatch(
    updateContact({
      account: profile.account,
      successful_payment: moment(new Date()).format('DD/MM/YYYY hh:mm:ss'),
    }),
  );
  if (window.FM) {
    window.FM.trackCustomEvent('Payment Successfull', {
      email: org.default_email,
    });
  }
  return dispatch({ type: 'PAYMENT_SUCCESS' });
};

export const reportPaymentIntentCreated = paymentIntent => ({
  type: 'PAYMENT_INTENT_CREATED',
  paymentIntent,
});

export const reportPaymentRequiresAuthentication = (
  paymentIntentClientSecret,
  paymentType,
  componentToAddon,
  subscriptionItemId,
) => ({
  type: 'PAYMENT_REQUIRES_AUTHENTICATION',
  paymentIntentClientSecret,
  paymentType,
  componentToAddon,
  subscriptionItemId,
});

export const reportPaymentError = err => () => (dispatch, getState) => {
  //Report Payment Faliure to Freshmarketer
  const profile = getState().account.profile;
  const org = getState().account.organisation;
  dispatch(
    updateContact({
      account: profile.account,
      failed_payment: moment(new Date()).format('DD/MM/YYYY hh:mm:ss'),
    }),
  );
  if (window.FM) {
    window.FM.trackCustomEvent('Payment Failed', {
      email: org.default_email,
    });
  }
  dispatch(openSnackbar(err.message));
  return dispatch({ type: 'PAYMENT_FAILURE', err });
};

export const submitCardDetails = stripe => (dispatch, getState) => {
  const { payment } = getState().account;
  dispatch({ type: 'SUBMITTING_CARD_DETAILS' });

  return new Promise((resolve, reject) => {
    return stripe.createToken(payment.number.value).then(response => {
      if (response.error) {
        dispatch(openSnackbar(response.error.message));
        dispatch({ type: 'PAYMENT_FAILURE', err: response.error.message });
        dispatch(reportPaymentError(response.error.message));
        return reject(response.error);
      }

      return resolve(response.token);
    });
  });
};

export const resetLocalCard = () => ({
  type: 'RESET_LOCAL_CARD',
});

export const hydrateCards = data => ({
  type: 'HYDRATE_CARDS',
  data,
});

export const useDefaultCard = () => ({
  type: 'USE_DEFAULT_CARD',
});

export const useNewCard = () => ({
  type: 'USE_NEW_CARD',
});

export const getDefaultCard = () => (dispatch, getState) => {
  const { cards } = getState().account.organisation;
  const defaultCard = cards instanceof Array ? cards[0] : undefined;

  dispatch({ type: 'GETTING_DEFAULT_CARD' });

  if (defaultCard) {
    dispatch(hydrateCards(defaultCard));
    return dispatch(useDefaultCard());
  }

  return dispatch(useNewCard());
};

// Gets a token from stripe.
// Server will create a stripe user if non exists already.
export const saveCard = stripe => (dispatch, getState) => {
  dispatch({ type: 'SAVING_CARD' });

  const handler = response => {
    const myHeaders = new Headers();
    const { profile } = getState().account.auth;
    const { appname } = getState().app.appContext;

    const payload = {
      token: response.id,
      card: response.card,
      account: profile.account,
      appname,
    };

    return firebase
      .auth()
      .currentUser.getIdToken()
      .then(token => {
        myHeaders.append('Content-Type', 'application/json');
        myHeaders.append('x-access-token', token);

        return fetch(`${process.env.REACT_APP_PAYMENT_API}/save_card`, {
          method: 'post',
          headers: myHeaders,
          body: JSON.stringify(payload),
          mode: 'cors',
        });
      })
      .then(res => {
        if (res.status === 200) {
          dispatch(reportPaymentSuccess());
          dispatch(getDefaultCard());
          dispatch(
            openSnackbar(i18n.t('Your card has been added successfully')),
          );
        }
        return res.json();
      })
      .then(data => {
        if (
          (typeof data === 'string' &&
            data.split('.')[0] &&
            data.split('.')[0] === 'Your card was declined') ||
          data.split('.')[0] === 'Your card number is incorrect'
        ) {
          dispatch(openSnackbar(i18n.t('Failed to Process, please try again')));
          dispatch({
            type: 'PAYMENT_FAILURE',
            err: i18n.t('Failed to Process, please try again'),
          });
          dispatch(
            reportPaymentError(i18n.t('Failed to Process, please try again')),
          );
        } else {
          dispatch(openSnackbar(data.split('.')[0]));
          dispatch({ type: 'PAYMENT_FAILURE', err: data.split('.')[0] });
          dispatch(reportPaymentError(data.split('.')[0]));
        }
      })
      .catch(error => console.log(error));
  };

  return dispatch(submitCardDetails(stripe))
    .then(handler)
    .catch(err => {
      dispatch(openSnackbar(err.message));
      dispatch({ type: 'PAYMENT_FAILURE', err });
      reportPaymentError(err);
    });
};

export const removeExistingCard = () => (dispatch, getState) => {
  const { cards } = getState().account.organisation;
  const defaultCard = cards instanceof Array ? cards[0] : undefined;

  const myHeaders = new Headers();
  const { profile } = getState().account.auth;

  const payload = {
    cardId: defaultCard.id,
    accountId: profile.account,
  };

  return firebase
    .auth()
    .currentUser.getIdToken()
    .then(token => {
      myHeaders.append('Content-Type', 'application/json');
      myHeaders.append('x-access-token', token);

      return fetch(`${process.env.REACT_APP_PAYMENT_API}/remove_card`, {
        method: 'post',
        headers: myHeaders,
        body: JSON.stringify(payload),
        mode: 'cors',
      });
    })
    .then(res => {
      if (res.status === 200) {
        dispatch(resetLocalCard());
        dispatch({ type: 'UPDATE_ACCOUNT', prop: 'cards', data: {} });
        dispatch(
          openSnackbar(i18n.t('Your card has been removed successfully')),
        );
      } else {
        dispatch(
          openSnackbar(
            i18n.t(
              'There was an error removing your card, please contact support',
            ),
          ),
        );
      }
    });
};

export const clearAutopopupNotices = () => (dispatch, getState) => {
  const account = getState().account;
  const allNotices = account.organisation.notices;
  if (allNotices) {
    const autoPopup = findFirst(allNotices, o => k =>
      o[k].autoPopup === false && o[k].type === 'Offer',
    );
    if (autoPopup) {
      firebase
        .database()
        .ref(`accounts/${account.profile.account}/notices/${autoPopup}`)
        .update({ autoPopup: null, read: false });
    }
  }
  return dispatch({ type: 'CLEAR_AUTOPOPUP' });
};

export const getPromoCodeDetails = (promoCode, productId) => async (
  dispatch,
  getState,
) => {
  dispatch({
    type: 'FETCHING_PROMO_CODE',
  });
  const myHeaders = new Headers();

  const token = await firebase.auth().currentUser.getIdToken();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('x-access-token', token);
  const payload = {
    promoCode,
    productId,
  };
  const res = await fetch(
    `${process.env.REACT_APP_PAYMENT_API}/promo_code/get_details`,
    {
      method: 'post',
      headers: myHeaders,
      mode: 'cors',
      body: JSON.stringify(payload),
    },
  );

  const promoCodeDetails = await res.json();
  dispatch({
    type: 'FETCHED_PROMO_CODE',
    promoCodeDetails,
  });
};
