import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Payment from './Payment';
import CreditCard from '../../components/creditCard';
import CreditCardForm from '../../components/creditCardForm';
import {
  handleFormChange,
  clearPaymentForm,
  getDefaultCard,
  useNewCard,
  useDefaultCard,
  removeExistingCard,
  getPromoCodeDetails,
} from './actions';

import { openLegalModal } from '../legal/actions';

class PaymentWrapper extends Component {
  static propTypes = {
    // passed as own props
    title: PropTypes.node,
    subtitle: PropTypes.node,
    cost: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    submitLabel: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
    // mapped via connect
    payment: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
    getDefaultCard: PropTypes.func.isRequired,
    clearPaymentForm: PropTypes.func.isRequired,
    addNewCard: PropTypes.func.isRequired,
    removeCard: PropTypes.func.isRequired,
    useExistingCard: PropTypes.func.isRequired,
    intl: PropTypes.shape({
      currency: PropTypes.string.isRequired,
    }).isRequired,
    showProrating: PropTypes.bool,
    period: PropTypes.string,
    plan: PropTypes.string,
    subscriptionId: PropTypes.string,
    prorating: PropTypes.object,
    stripe: PropTypes.object,
    stripeAuthSuccessCallback: PropTypes.func,
    stripeAuthErrorCallback: PropTypes.func,
  };

  static defaultProps = {
    title: null,
    subtitle: null,
    prorating: {},
    period: null,
    showProrating: false,
    subscriptionId: null,
    plan: null,
    productId: null,
  };

  componentWillMount() {
    this.props.getDefaultCard();
  }

  componentWillUnmount() {
    this.props.clearPaymentForm();
  }

  render() {
    return (
      <Payment
        title={this.props.title}
        isCurrentPlanDIY={this.props.isCurrentPlanDIY}
        subtitle={this.props.subtitle}
        cost={this.props.cost}
        currency={this.props.currency}
        submitLabel={this.props.submitLabel}
        onSubmit={this.props.onSubmit}
        intl={this.props.intl}
        prorating={this.props.prorating}
        period={this.props.period}
        showProrating={this.props.showProrating}
        stripe={this.props.stripe}
        stripeAuthSuccessCallback={this.props.stripeAuthCallback}
        {...this.props}
        {...this.props.payment}
      >
        {this.props.payment.useDefaultCard && (
          <CreditCard
            {...this.props.payment.defaultCard}
            onAddCard={this.props.addNewCard}
            onRemoveCard={this.props.removeCard}
          />
        )}
        {!this.props.payment.useDefaultCard && (
          <CreditCardForm
            {...this.props.payment}
            onChange={this.props.handleChange}
            useExistingCard={
              this.props.payment.hasDefaultCard
                ? this.props.useExistingCard
                : undefined
            }
          />
        )}
      </Payment>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  lastRedeemedCoupon: state.account.organisation.lastRedeemedCoupon,
  payment: state.account.payment,
  intl: state.account.intl,
  country:
    (state.account.auth.profile.ip_data &&
      state.account.auth.profile.ip_data.showDynamicPricing &&
      state.account.auth.profile.ip_data.country &&
      state.account.auth.profile.ip_data.country
        .replace(/\d+/g, ' ')
        .split(/ |\B(?=[A-Z])/)
        .map(word => word.toLowerCase())
        .join('_')) ||
    '',
});

const mapDispatchToProps = dispatch => ({
  handleChange: (prop, value, error) =>
    dispatch(handleFormChange(prop, value, error)),
  getDefaultCard: () => dispatch(getDefaultCard()),
  clearPaymentForm: () => dispatch(clearPaymentForm()),
  addNewCard: () => dispatch(useNewCard()),
  removeCard: () => dispatch(removeExistingCard()),
  useExistingCard: () => dispatch(useDefaultCard()),
  onOpenLegalModal: value => dispatch(openLegalModal(value)),
  getPromoCodeDetails: (promoCode, productId) =>
    dispatch(getPromoCodeDetails(promoCode, productId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentWrapper);
