import moment from 'moment';
import _clone from 'lodash/clone';
import _forIn from 'lodash/forIn';
import _max from 'lodash/max';
import _get from 'lodash/get';

const DATE_FORMAT = 'DD MMM';

const getChartMax = nextState => {
  const offset = 5;
  const maxValue = _max([
    nextState.installs && nextState.installs.maxValue,
    nextState.shares && nextState.shares.maxValue,
    nextState.visits && nextState.visits.maxValue,
  ]);

  return maxValue < 10 ? 10 : maxValue + offset;
};

const setLabels = (
  step,
  numWeeks,
  dateFormat = DATE_FORMAT,
  customStartDate,
  customEndDate,
) => {
  let now;
  let initialTime;

  if (customEndDate !== undefined) {
    initialTime = customStartDate;
    now = customEndDate;

    if (step === 'month') {
      now = moment(now).startOf('month');
      initialTime = moment(initialTime)
        .subtract(1, 'month')
        .startOf('month');
    } else if (moment(now).isoWeekday() < 7) {
      now = moment(now).isoWeekday(7);
    }
  } else {
    now = moment().startOf(step);
    initialTime = moment()
      .subtract(numWeeks, 'week')
      .startOf(step);
  }

  const totalAxis = now.diff(initialTime, step);
  const labelsMap = new Map();
  const labels = [];
  const initialValues = [];

  for (let i = 0; i < totalAxis; i++) {
    const clone = now.clone();
    clone.subtract(i, step);
    const key = clone.format(dateFormat);
    labelsMap.set(key, 0);
  }

  labelsMap.forEach((value, key) => {
    console.log(key);
    labels.unshift(key);
    initialValues.unshift(0);
  });

  return {
    labels,
    labelsMap,
    initialValues,
  };
};

const sortTimeValues = (valuesMap, data, step, dateFormat = DATE_FORMAT) => {
  const values = [];
  let count = 0;

  if (!data) {
    return {
      values,
      valuesMap,
      count,
      maxValue: 10,
    };
  }

  _forIn(data, value => {
    const day = moment(_get(value, 'timestamp', value))
      .startOf(step)
      .format(dateFormat);
    const sum = valuesMap.get(day);
    count++;

    valuesMap.set(day, sum + 1);
  });

  valuesMap.forEach(value => values.unshift(value));

  const maxValue = _max(values);

  return {
    values,
    count,
    valuesMap,
    maxValue,
  };
};

const lineChartReducer = (state = {}, action) => {
  switch (action.type) {
    case 'INITIALIZE_LINE_CHART': {
      const { labels, labelsMap, initialValues } = setLabels(
        action.step,
        action.numWeeks,
        action.dateFormat,
        action.customStartDate,
        action.customEndDate,
      );
      return {
        ...state,
        [action.appname]: {
          initialized: true,
          ...state[action.appname],
          labels,
          labelsMap,
          visits: {
            values: initialValues,
          },
          installs: {
            values: initialValues,
          },
          shares: {
            values: initialValues,
          },
        },
      };
    }

    case 'RECEIVE_LINE_CHART_DATA': {
      const valuesMap = _clone(state[action.appname].labelsMap);
      const lineData = sortTimeValues(
        valuesMap,
        action.data,
        action.step,
        action.dateFormat,
      );

      const nextAppState = {
        ...state[action.appname],
        [action.key]: lineData,
      };

      return {
        ...state,
        [action.appname]: {
          ...nextAppState,
          settings: {
            numWeeks: action.numWeeks,
            step: action.step,
            dateFormat: action.dateFormat,
          },
          maxValue: getChartMax(nextAppState),
        },
      };
    }

    default:
      return state;
  }
};

export default lineChartReducer;
