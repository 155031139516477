import i18n from 'i18next';
import firebase from '../../../../../../../firebase';
import { newValidator } from '../../../../../../../utils';

import { getSubscriptions } from '../../../../../../account/actions';

const resetAppForm = () => ({
  type: 'RESET_APP_FORM',
});

const reportError = err => ({
  type: 'CREATE_APP_ERROR',
  err,
});

export const skinChange = value => ({
  type: 'CREATE_APP_SKIN_CHANGE',
  value,
});

export const titleChange = value => ({
  type: 'CREATE_APP_TITLE_CHANGE',
  value,
});

const checkingDomainAvailability = () => ({
  type: 'CHECKING_DOMAIN_AVAILABILITY',
});

const assertDomainAvailability = value => ({
  type: 'ASSERT_DOMAIN_AVAILABILITY',
  value,
});

// validate domain in the action so we know whether to check domain availability
const domainValidator = newValidator({
  required: true,
  maxLength: 60,
  type: 'domain',
});

export const filterTemplates = searchText => ({
  type: 'FILTER_TEMPLATES',
  value: searchText,
});

export const domainChange = value => dispatch => {
  const isValid = domainValidator(value);
  dispatch({
    type: 'CREATE_APP_DOMAIN_CHANGE',
    value,
    isValid,
  });
  if (isValid) {
    dispatch(checkingDomainAvailability());

    firebase
      .database()
      .ref('apps_directory')
      .child(value)
      .once('value')
      .then(snapshot => {
        const isAvailable = !snapshot.exists();
        if (isAvailable) {
          firebase
            .database()
            .ref('apps')
            .child(value)
            .once('value')
            .then(appsSnapshot => {
              dispatch(assertDomainAvailability(!appsSnapshot.exists()));
            });
        } else {
          dispatch(assertDomainAvailability(isAvailable));
        }
      });
  }
};

const creatingApp = (form, accountId) => ({
  type: 'CREATING_APP',
  form,
  accountId,
});

export const createNewApp = callback => (dispatch, getState) => {
  const { domain, title, skin } = getState().editor.build.create.createApp;
  const { account } = getState().account.profile;
  const legacy = getState().account.organisation.legacy_flag;
  const appLimit = typeof legacy === 'number' ? legacy : 50;
  const user = firebase.auth().currentUser;
  const apps = getState().account.organisation.apps;

  dispatch(creatingApp({ domain, title, skin }, account));
  if (legacy || apps === null) {
    if (apps === null || Object.keys(apps).length < appLimit) {
      return (
        user
          .getIdToken()
          .then(token => {
            const headers = new Headers();
            headers.append('Content-Type', 'application/json');
            headers.append('x-access-token', token);

            return fetch(`${process.env.REACT_APP_CREATE_API}/app`, {
              headers,
              method: 'post',
              mode: 'cors',
              body: JSON.stringify({
                domain: domain.value,
                title: title.value,
                skin: skin.type,
                content: skin.content,
              }),
            });
          })
          // we need to refresh the subscription state after creating a new app
          .then(res => {
            if (res.status === 200) {
              return dispatch(getSubscriptions());
            }
            return res.json();
          })
          .then(result => {
            if (result.error) {
              throw new Error(result.error);
            }
          })
          .then(() =>
            firebase
              .database()
              .ref(`accounts/${account}/apps`)
              .once('child_added'),
          )
          .then(() => {
            // Track App Creation in analytics
            callback();
            setTimeout(() => dispatch(resetAppForm()), 500);
          })
          .catch(err => dispatch(reportError(err.message)))
      );
    }

    return dispatch(
      reportError(
        `${i18n.t('Sorry, but you cannot have more than')} ${appLimit} ${i18n.t(
          'apps.',
        )}`,
      ),
    );
  }

  return dispatch(
    reportError(i18n.t('Sorry, but you can only have one app per account.')),
  );
};
