import { combineReducers } from 'redux';
import i18n from 'i18next';
import contacts from './modules/contacts/reducer';
import history from './modules/history/reducer';
import { getAppUrl } from '../../utils/appDetails';
import { UPDATE_CUSTOM_DOMAIN_DATA } from '../editor/modules/shared/customDomain/actions';

const initialState = {
  open: false,
  pending: false,
  mode: 'sms',
  message: {
    value: null,
  },
  subject: {
    value: '',
  },
  messageOptions: [],
  title: '',
  customDomain: {},
  whatsappMessage: {
    value: null,
  },
  whatsappMessageOptions: [],
};

const getDefaultMessage = (appname, title, customDomain) => ({
  message: {
    value: `${i18n.t('Hey')} {{user_name}}! ${i18n.t(
      'Check out our awesome app',
    )} @ ${getAppUrl(appname, customDomain)}`,
  },
  messageOptions: [
    `${i18n.t('Hey')} {{user_name}}! ${i18n.t(
      'Check out our awesome app',
    )} @ ${getAppUrl(appname, customDomain)}`,
    `${i18n.t(
      'Get the latest news and information from our app',
    )} @ ${getAppUrl(appname, customDomain)}`,
    `${i18n.t('Hey')} {{user_name}}! ${title} ${i18n.t(
      'is now available for download',
    )} @ ${getAppUrl(appname, customDomain)}`,
    `${i18n.t('Hey')} {{user_name}}! ${i18n.t(
      'You can checkout our app by clicking on the following link',
    )} - ${getAppUrl(appname, customDomain)}`,
  ],
  customDomain,
});

const getWhatsappMessage = (appname, title, customDomain) => ({
  whatsappMessage: {
    value: `${i18n.t('The')} ${title || ''} ${i18n.t(
      'app can now be downloaded',
    )} @ ${getAppUrl(appname, customDomain)}`,
  },
  whatsappMessageOptions: [
    {
      value: `${i18n.t('The')} ${title || ''} ${i18n.t(
        'app can now be downloaded',
      )} @ ${getAppUrl(appname, customDomain)}`,
      index: 0,
    },
    {
      value: `${i18n.t('Hey John! You can checkout our app')} @ ${getAppUrl(
        appname,
        customDomain,
      )}`,
      index: 1,
    },
    {
      value: `${i18n.t(
        'Hey John! You can checkout our app by clicking on the following link',
      )} - ${getAppUrl(appname, customDomain)}`,
      index: 2,
    },
  ],
  customDomain,
});

const shareReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_DISTRIBUTE_MESSAGE':
      return {
        ...getDefaultMessage(action.appname, state.title, action.customDomain),
      };

    case 'SET_WHATSAPP_MESSAGE':
      return {
        ...state,
        ...getWhatsappMessage(action.appname, state.title, action.customDomain),
      };
    case 'OPEN_DISTRIBUTE_DIALOG':
      return {
        ...state,
        open: true,
      };

    case 'CLOSE_DISTRIBUTE_DIALOG':
      return {
        ...state,
        open: false,
      };

    case 'CHANGE_DISTRIBUTE_MODE':
      return {
        ...state,
        mode: action.mode,
      };

    case UPDATE_CUSTOM_DOMAIN_DATA: {
      return {
        ...state,
        customDomain: {
          ...state.customDomain,
          ...action.customDomain,
        },
      };
    }

    case 'DISTRIBUTE_MESSAGE_CHANGE':
      return {
        ...state,
        message: {
          isValid: action.value !== '',
          value: action.value,
        },
      };

    case 'WHATSAPP_MESSAGE_CHANGE':
      return {
        ...state,
        whatsappMessage: {
          isValid: action.value !== '',
          value: action.value,
        },
      };

    case 'DISTRIBUTE_SUBJECT_CHANGE':
      return {
        ...state,
        subject: {
          value: action.value ? action.value : '',
        },
      };
    case 'RECEIVE_APP_TITLE':
      return {
        ...state,
        subject: {
          value: action.data,
        },
        title: action.data,
        ...getDefaultMessage(action.appname, action.data, action.customDomain),
        ...getWhatsappMessage(action.appname, action.data, action.customDomain),
      };

    case 'DISPATCH_DISTRIBUTE':
      return {
        ...state,
        pending: true,
      };

    case 'DISTRIBUTE_DISPATCH_COMPLETE':
      return {
        ...state,
        open: false,
        pending: false,
      };

    default:
      return state;
  }
};

export default combineReducers({
  share: shareReducer,
  contacts,
  history,
});
